<template>
    <div class="activity">
        <div class="mobilebtn flex">
            <div class="flexcenter" @click="handlerTop(1)"><img src="../../public/images/new/inviteicon1.png" /> </div>
            <div class="flexcenter" @click="handlerTop(2)"><img src="../../public/images/new/inviteicon2.png" /></div>
            <div class="flexcenter" @click="handlerTop(3)"><img src="../../public/images/new/inviteicon3.png" /> </div>
            <div class="flexcenter" @click="handlerTop(4)"><img src="../../public/images/new/inviteicon4.png" /></div>
        </div>
        <div class="activity-top flex animate__animated animate__fadeInUp">
            <div class="top-left">
                <div class="left-name">{{ $t('invite.desc50') }}</div>
                <div class="left-desc">{{ $t('invite.desc51') }}</div>
                <div class="left-time">{{ $t('invite.desc52') }}</div>
                <div class="left-total flex">
                    <div class="total">
                        <!-- <el-tooltip class="box-item" raw-content effect="dark"
                                :content="$t('invite.desc91')" placement="bottom">
                                <img src="../../public/images/new/tips.png" alt="" class="img1" />
                            </el-tooltip> -->
                        <div>{{ $t('invite.desc53') }} </div>
                        <p v-if="info.totalPoolNum != undefined">{{ $t('invite.desc64', { n1: info.totalPoolNum}) }}</p>
                        <p v-else>{{ $t('invite.desc64', { n1: '--'}) }}</p>
                    </div>
                    <div class="total">
                        <div>{{ $t('invite.desc54') }}</div>
                        <p>$ {{ info.totalAmount || 100000}}</p>
                    </div>
                </div>
                <div class="left-btn flexcenter" @click="handlerInvite">{{ $t('invite.desc3') }}</div>
            </div>
            <div class="top-right">
                <div class="top-name">{{ $t('invite.desc55') }}</div>
                <div class="top-rules">{{ $t('invite.desc56') }}</div>
                <div class="rules-rate">
                    <div class="flex">
                        <div>{{ $t('invite.desc57') }}</div>
                        <p>4000 DU</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc58') }}</div>
                        <p>2500 DU</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc59') }}</div>
                        <p>2000 DU</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc60') }}</div>
                        <p>1000 DU</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc61') }}</div>
                        <p>500 DU</p>
                    </div>
                </div>
                <div class="top-rules">{{ $t('invite.desc62') }}</div>
                <div class="top-rules">{{ $t('invite.desc63') }}</div>
            </div>
        </div>
        <div class="activity-title flex" id="act1">
            <img src="../../public/images/new/inviteicon6.png" />
            <div>{{ $t('invite.desc46') }}</div><img src="../../public/images/new/inviteicon5.png" />
        </div>
        <div class="rules-list animate__animated animate__fadeInUp animate__delay-.4s">
            <div v-html="$t('invite.desc65')"></div>
            <div>{{ $t('invite.desc651') }}</div>
            <div>{{ $t('invite.desc66') }}</div>
            
            <div v-html="$t('invite.desc67')"></div>
            <div v-html="$t('invite.desc68')"></div>
            <div v-html="$t('invite.desc69')"></div>
            <div>{{ $t('invite.desc70') }}</div>
            <div>{{ $t('invite.desc71') }}</div>
        </div>
        <div class="activity-title flex animate__animated animate__fadeInUp animate__delay-.8s" id="act2">
            <img src="../../public/images/new/inviteicon6.png" />
            <div>{{ $t('invite.desc72') }}</div><img src="../../public/images/new/inviteicon5.png" />
        </div>
        <div class="park animate__animated animate__fadeInUp animate__delay-.8s">
            <div class="park-top flex">
                <div>{{ $t('invite.desc73') }}</div>
                <div>{{ $t('invite.desc74') }}</div>
                <div>{{ $t('invite.desc75') }}</div>
            </div>
            <div class="nulldata" v-if="info.rankingList == ''">
                <img src="../../public/images/no-data.png" alt="" />
                <p>{{ $t("record.desc13") }}</p>
            </div>
            <div class="park-list">
                <div class="list-info flex" v-for="(item, index) in info.rankingList" :key="item.id">
                    <div>
                        <img src="../../public/images/new/pank1.png" v-if="index == 0" />
                        <img src="../../public/images/new/pank2.png" v-else-if="index == 1" />
                        <img src="../../public/images/new/pank3.png" v-else-if="index == 2" />
                        <img src="../../public/images/new/pank4.png" v-else-if="index == 3" />
                        <img src="../../public/images/new/pank5.png" v-else-if="index == 4" />
                        <p v-else>{{ index + 1 }}</p>
                    </div>
                    <div>UID {{ dealAddress(item.userId) }}</div>
                    <div>{{ item.inviteNum }}</div>
                </div>
            </div>
        </div>
        <div class="park-rules animate__animated animate__fadeInUp animate__delay-.8s">
            <div>{{ $t('invite.desc76') }}</div>
            <!-- <div>{{ $t('invite.desc77') }}</div> -->
        </div>
        <div class="activity-title flex animate__animated animate__fadeInUp animate__delay-1s">
            <img src="../../public/images/new/inviteicon6.png" />
            <div>{{ $t('invite.desc86') }}</div><img src="../../public/images/new/inviteicon5.png" />
        </div>
        <div class="park animate__animated animate__fadeInUp animate__delay-1s">
            <div class="park-top flex">
                <div>{{ $t('invite.desc87') }}</div>
                <div>{{ $t('invite.desc74') }}</div>
                <div>{{ $t('invite.desc78') }}</div>
            </div>
            <div class="nulldata" v-if="info.additionalUsers == ''">
                <img src="../../public/images/no-data.png" alt="" />
                <p>{{ $t("record.desc13") }}</p>
            </div>
            <div class="park-list">
                <div class="list-info flex" v-for="(item, index) in info.additionalUsers" :key="item.id">
                    <div>
                        {{ index + 1 }}
                    </div>
                    <div>UID {{ dealAddress(item.userId) }}</div>
                    <div>{{ returnTime(item.createTime) }}</div>
                </div>
            </div>
        </div>
        <div class="park-rules animate__animated animate__fadeInUp animate__delay-1s">
            <div>{{ $t('invite.desc79') }}</div>
        </div>
        <div class="activity-title flex animate__animated animate__fadeInUp animate__delay-1.2s" id="act3">
            <img src="../../public/images/new/inviteicon6.png" />
            <div>{{ $t('invite.desc48') }}</div><img src="../../public/images/new/inviteicon5.png" />
        </div>
        <div class="park animate__animated animate__fadeInUp animate__delay-1.2s">
            <div class="park-top flex">
                <div>{{ $t('invite.desc87') }}</div>
                <div>{{ $t('invite.desc74') }}</div>
                <div>{{ $t('invite.desc78') }}</div>
            </div>
            <div class="nulldata" v-if="info.myInviteUsers == ''">
                <img src="../../public/images/no-data.png" alt="" />
                <p>{{ $t("record.desc13") }}</p>
            </div>
            <div class="park-list">
                <div class="list-info flex" v-for="(item, index) in info.myInviteUsers" :key="item.id">
                    <div>
                        {{ index + 1 }}
                    </div>
                    <div>UID {{ dealAddress(item.userId) }}</div>
                    <div>{{ returnTime(item.createTime) }}</div>
                </div>
            </div>
        </div>
        <div class="park-rules animate__animated animate__fadeInUp animate__delay-1.2s">
            <div>{{ $t('invite.desc80') }}</div>
        </div>

        <div class="activity-title flex animate__animated animate__fadeInUp animate__delay-1.6s" id="act4">
            <img src="../../public/images/new/inviteicon6.png" />
            <div>{{ $t('invite.desc49') }}</div><img src="../../public/images/new/inviteicon5.png" />
        </div>
        <div class="park1 animate__animated animate__fadeInUp animate__delay-1.6s">
            <div class="park-top flex">
                <div>{{ $t('invite.desc81') }}</div>
                <div>{{ $t('invite.desc82') }}</div>
                <div>{{ $t('invite.desc83') }}</div>
                <div>{{ $t('invite.desc84') }}</div>
                <div>{{ $t('invite.desc85') }}</div>
            </div>
            <div class="nulldata" v-if="info.userAmountRecordList == ''">
                <img src="../../public/images/no-data.png" alt="" />
                <p>{{ $t("record.desc13") }}</p>
            </div>
            <div class="park-list">
                <div class="list-info flex" v-for="(item, index) in info.userAmountRecordList" :key="item.id">
                    <div>{{ returnTime(item.createTime) }}</div>
                    <div>{{ item.remark }}</div>
                    <div>{{ item.coinName }}</div>
                    <div>{{ item.amount }}</div>
                    <div>{{ $t('withdraw.desc40') }}</div>
                </div>
            </div>
            <div class="h5list">
                <div class="list-info" v-for="(item, index) in info.userAmountRecordList" :key="item.id">
                    <div class="flex">
                        <div>{{ $t('invite.desc81') }}</div>
                        <p>{{ returnTime(item.createTime) }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc82') }}</div>
                        <p>{{ item.remark }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc83') }}</div>
                        <p>{{ item.coinName }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc84') }}</div>
                        <p>{{ item.amount }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('invite.desc85') }}</div>
                        <p>{{ $t('withdraw.desc40') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            info: {}
        }
    },
    mounted() {
        this.$post(this.URL.invite.activity, {}).then(res => {
            if (res.code == 0) {
                this.info = res.data;
            }
        })
    },
    methods: {
        returnTime(date) {
            let time = this.common.dateBJtoLocal(date), time1 = time.getTime(), time2 = 4 * 60 * 60 * 1000;
            let datetime = this.common.formatDate(time1 + time2);
            return datetime;
        },
        handlerTop(id) {

            let Id = `act${id}`;
            const section = document.getElementById(Id);
            console.log(section, Id)
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
            // window.location.href = `act${this.topId}`
        },
        handlerInvite() {
            this.$emit('invitebtn')
        },
        dealAddress(str) {
            if (str == "" || str == undefined || str == null) return "--";
            if (str && str.length == 3) {
                return (
                    str.substring(0, 2) +
                    "****" +
                    str.substring(str.length - 1, str.length)
                );
            } else if (str && str.length == 2) {
                return (
                    str.substring(0, 2) +
                    "*****"
                );
            } else if (str && str.length == 1) {
                return (
                    str.substring(0, 1) +
                    "******"
                );
            } else {
                return (
                    str.substring(0, 2) +
                    "***" +
                    str.substring(str.length - 2, str.length)
                );
            }
        },
    }
}
</script>

<style lang="less" scoped>
.activity {
    width: 1240px;
    margin: 0 auto;
    padding-top: 56px;

    .activity-top {
        .top-left {
            flex: 1;
            margin-right: 20px;

            .left-name {
                padding: 24px 0 40px;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 24px;
            }

            .left-desc {
                font-size: 56px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: 56px;
            }

            .left-time {
                padding: 24px 0 88px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.8);
            }

            .left-total {
                .total {
                    margin-right: 120px;

                    &:last-child {
                        margin-right: 0;
                    }

                    font-size:32px;
                    color: #FFFFFF;

                    div {
                        margin-bottom: 16px;
                        font-size: 14px;
                        color: #A8A8A8;
                        line-height: 14px;
                        display: flex;

                        img {
                            width: 14px;
                            height: 14px;
                            margin-left: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .left-btn {
                margin-top: 52px;
                width: 208px;
                height: 52px;
                background: url('../../public/images/new/btnbg5.png') center no-repeat;
                background-size: 100% 100%;
                font-size: 16px;
                color: #000000;
                cursor: pointer;
            }
        }

        .top-right {
            flex: 0 0 440px;
            padding: 32px;
            background: rgba(0, 0, 0, 1);
            border: 1px solid rgba(255, 255, 255, 0.2);
            height: fit-content;

            .top-name {
                margin-bottom: 12px;
                font-size: 20px;
                color: #C9FA5B;
                line-height: 20px;
                font-weight: 600;
            }

            .top-rules {
                padding-top: 8px;
                font-size: 14px;
                color: #FFFFFF;
            }

            .rules-rate {
                margin: 16px 0;
                padding: 4px 20px;
                border-radius: 8px;
                background: #181818;

                .flex {
                    padding: 16px 0;
                    font-size: 14px;
                    color: #FFFFFF;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.06);

                    div,
                    p {
                        flex: 1;
                    }

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    .activity-title {
        padding: 120px 0 56px;
        justify-content: center;
        font-size: 40px;
        color: #C9FA5B;
        line-height: 40px;
        font-weight: 600;

        div {
            margin: 0 16px;
            text-shadow: 0px 0px 20px rgba(201, 250, 91, 0.40);
        }

        img {
            width: 95px;
            height: 17px;
            margin-top: 12px;
        }
    }

    .rules-list {
        font-size: 16px;
        color: #fff;

        div {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .park {
        .park-top {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            justify-content: space-between;

            div {
                flex: 1;

                &:nth-child(2) {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }

        .park-list {
            margin-top: 11px;

            .list-info {
                padding-top: 32px;
                font-size: 16px;
                color: #FFFFFF;
                justify-content: space-between;
                line-height: 22px;

                img {
                    width: 22px;
                    height: 22px
                }

                div {
                    flex: 1;

                    &:nth-child(2) {
                        text-align: center;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    .park1 {
        .h5list {
            display: none;
        }

        .park-top {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            justify-content: space-between;

            div {
                flex: 0 0 20%;
                text-align: center;

                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }

        .park-list {
            margin-top: 11px;

            .list-info {
                padding-top: 32px;
                font-size: 16px;
                color: #FFFFFF;
                justify-content: space-between;
                line-height: 22px;

                img {
                    width: 22px;
                    height: 22px
                }

                div {
                    flex: 0 0 20%;
                    text-align: center;

                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    .nulldata {
        padding: 80px 0 40px;
        font-size: 14px;
        color: #A1A1A1;
        text-align: center;

        img {
            width: 90px;
            height: 90px;
        }
    }

    .park-rules {
        margin-top: 40px;
        font-size: 16px;
        color: #FFFFFF
    }
}

.mobilebtn {
    display: none;
}

@media (max-width:1200px) {
    .activity {
        width: 100%;

        .activity-top {
            .top-left {
                .left-name {
                    padding: 12px 0 20px;
                    font-size: 16px;
                }

                .left-desc {
                    font-size: 32px;
                }

                .left-time {
                    padding: 12px 0 40px;
                }

                .left-total {
                    .total {
                        flex: 1;
                        margin-right: 0;
                    }
                }
            }

            .top-right {
                flex: 0 0 320px;
                padding: 16px;
                height: fit-content;
            }
        }
    }

}

@media (max-width:767px) {
    .mobilebtn {
        padding: 0 0 20px;
        display: flex;
        justify-content: flex-end;

        div {
            width: 32px;
            height: 32px;
            margin-right: 4px;
            background: #181818;
            border-radius: 50%;
        }

        img {
            width: 20px;
            height: 20px;

        }
    }

    .activity {
        margin: 0;
        padding: 0;

        .activity-top {
            flex-wrap: wrap;

            .top-left {
                flex: 0 0 100%;
                margin-right: 0;

                .left-name {
                    padding: 0 0 20px;
                    font-size: 16px;
                    text-align: center;
                }

                .left-desc {
                    font-size: 32px;
                    line-height: 32px;
                    text-align: center;
                }

                .left-time {
                    font-size: 14px;
                    padding: 16px 0 32px;
                    text-align: center;
                }

                .left-total {

                    .total {
                        font-size: 20px;
                    }
                }

                .left-btn {
                    width: 100%;
                    margin: 32px 0;
                    background: url('../../public/images/new/btnbg1.png') center no-repeat;
                    background-size: 100% 100%;
                }
            }

            .top-right {
                flex: 0 0 100%;
                padding: 20px 16px;

                .top-name {
                    margin-bottom: 0;
                    font-size: 18px;
                }

                .top-rules {
                    font-size: 13px;
                }

                .rules-rate {
                    border-radius: 4px;
                    padding: 0 14px;

                    .flex {
                        font-size: 13px;
                    }
                }
            }
        }

        .activity-title {
            padding: 48px 0 24px;
            font-size: 20px;
            line-height: 20px;

            div {
                margin: 0 8px;
            }

            img {
                margin-top: 1.5px;
            }
        }

        .rules-list {
            font-size: 13px;
        }

        .park {
            .park-top {
                display: none;
            }

            .park-list {
                .list-info {
                    padding-top: 24px;

                    &:first-child {
                        padding-top: 0;
                    }

                    div {
                        flex: initial;

                        &:first-child {
                            flex: 0 0 24px;
                        }

                        &:nth-child(2) {
                            text-align: left;
                        }

                        &:last-child {
                            flex: 1;
                        }
                    }
                }

            }
        }

        .park-rules {
            margin-top: 32px;
            font-size: 14px;
        }

        .park1 {

            .park-top,
            .park-list {
                display: none;
            }

            .h5list {
                display: block;

                .list-info {
                    padding: 20px 0;
                    border-bottom: 1px solid #1A1A1A;

                    &:last-child {
                        border-bottom: 0;
                    }

                    .flex {
                        justify-content: space-between;
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.6);

                        div {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
</style>